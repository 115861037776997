import Axios from "axios";
// import Vue from "vue";
// import AxiosPlugin from 'vue-axios-cors';
// Vue.use(AxiosPlugin)

let baseURL = process.env.VUE_APP_BACKEND_API;
//alert(baseURL)
// let baseURL = `https://grupa-api-pre-live.herokuapp.com/api`;
const user = JSON.parse(localStorage.getItem("grupa_user"));

const axios = Axios.create({
  baseURL,
  timeout: 100000,
});
// axios.defaults.headers.common
axios.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("grupa_user"));

    if (localStorage.getItem("grupa_user") === null) {
      console.log("user not logged In");
    } else {
      config.headers.Authorization = `Bearer ${user.token}`;
    }

    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["Access-Control-Allow-Headers"] = "*";
    config.headers.common["Access-Control-Allow-Credentials"] = "true";

    //'Access-Control-Allow-Credentials': 'true'
    //'Access-Control-Allow-Headers': '*',

    config.headers.common["Content-Type"] = `application/json`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// dev update project milestone/sprint

//auth/linkedin/callback

export const linkedInCallback = (payload) => {
  return axios.get(
    `${baseURL}/onboarding/client/auth/linkedin/callback?${payload}`
  );
};


export const GoogleCallback = (payload) => {
  return axios.get(
    `${baseURL}/onboarding/client/auth/google/callback?${payload}`
  );
};
export const devUpdateFeature = (payload) => {
  return axios.put(`${baseURL}/dev/project/feature`, payload);
};

// dev create new project task
export const devUpdateTask = (payload) => {
  return axios.post(
    `${baseURL}/dev/project/edit_task/${payload.task_id}`,
    payload
  );
};

// dev create new project task
export const devCreateTask = (payload) => {
  return axios.post(`${baseURL}/dev/project/task`, payload);
};

// Create/Add task to feature
export const createTaskToFeature = (payload) => {
  return axios.post(`${baseURL}/dev/project/create_task`, payload);
};

// dev create new project task
export const devDeleteTask = (id) => {
  return axios.delete(`${baseURL}/dev/project/task/` + id);
};

// Dev assign task to team member
export const devAssignTask = async (payload) => {
  const url = payload.replace ? "replace" : "add-dev";
  try {
    const resp = await axios.post(
      `${baseURL}/dev/project/task/${url}`,
      payload
    );
    return resp;
  } catch (error) {
    return error;
  }
};
// Link a task to feature
export const devLinkTask = (task_id, payload) => {
  return axios.post(`${baseURL}/dev/project/edit_task/${task_id}`, payload);
};

export const getDevProject = (id) => {
  return axios.get(`${baseURL}/test/get-project/` + id);
};

export const devUploadDocument = (payload) => {
  return axios.post(`${baseURL}/dev/project/upload_doc`, payload);
};

export const createFeatureComment = (payload) => {
  return axios.post(`${baseURL}/dev/project/create-feature-chat`, payload);
};

export const getFeatureComment = (id) => {
  return axios.get(`${baseURL}/dev/project/get-feature-chat/` + id);
};

export const updateDesignSprint = (payload) => {
  return axios.post(`${baseURL}/dev/edit-design-sprint`, payload);
};

export const teamAcceptSLA = (id) => {
  return axios.post(`${baseURL}/dev/project/sla`, id);
};

// dev delete project milestone/sprint
export const devDeleteFeature = (id) => {
  return axios.delete(`${baseURL}/dev/project/feature/` + id);
};

// Dev Wallet
export const teamWallet = () => {
  return axios.get(`${baseURL}/dev/wallet`);
};

export const inviteTeamOnboarding = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/invite_team`, payload);
};

export const getRoles = (payload) => {
  return axios.get(`${baseURL}/dev/roles`);
};

export const editTeam = (payload) => {
  return axios.post(`${baseURL}/dev/project/team/edit`, payload);
};

export const removeTeamUser = (payload) => {
  return axios.post(`${baseURL}/dev/settings/remove_member`, payload);
};

export const transferTeamLead = (payload) => {
  return axios.post(`${baseURL}/dev/team-lead/transfer`, payload);
};
// Team Service Plan Settings
export const teamSettings = (payload) => {
  return axios.post(`${baseURL}/dev/settings/select_workmode`, payload);
};

// Team Detele A Portfolio
export const deletePortfolio = (id) => {
  return axios.delete(`${baseURL}/dev/portfolio/${id}`);
};

// Team Replace Team Member in a Project
export const replaceTeamInProject = (payload) => {
  return axios.post(`${baseURL}/dev/project/developer/replace`, payload);
};

// All tools and frameWorks
export const getToolsAndFrameWorks = () => {
  return axios.get(`${baseURL}/repository/tools2`);
};

// Update Developer Info
export const updateDevInfo = (payload) => {
  return axios.post(`${baseURL}/dev/settings/update_user`, payload);
};

// Change Dev password
export const devChangePassword = (payload) => {
  return axios.put(`${baseURL}/dev/settings/password`, payload);
};

// Update Dev Tools And Frameworks
export const devUpdateToolsAndFramework = (payload) => {
  return axios.put(`${baseURL}/dev/settings/tools_language`, payload);
};

// Dev Add Education
export const devAddEducation = (payload) => {
  return axios.post(`${baseURL}/dev/create-education`, payload);
};

export const uploadIntroVideo = (payload) => {
  return axios.post(`${baseURL}/dev/settings/upload_team_video`, payload);
};

export const jumioInitialEndpoint = () => {
  return axios.post(`${baseURL}/onboarding/dev/get_jumio_redirect`);
};

// Update portfolio status
export const updateMiniProjectStatus = () => {
  return axios.get(`${baseURL}/dev/change/mini-project-status`);
};

// Update portfolio status
export const updatePortfolioStatus = () => {
  return axios.get(`${baseURL}/dev/change/user-portfolio-status`);
};

// Update video interview status
export const updateVideoInterviewStatus = () => {
  return axios.get(`${baseURL}/dev/change/user-video-interview-status`);
};

export const fetchLinkedinProfile = (code) => {
  return axios.get(`${baseURL}/dev/onboarding/linkedin/get-profile/${code}`);
};

export const fetchTeamWorkMode = (teamId) => {
  return axios.get(
    `${baseURL}/dev/settings/get/service-plans-work-modes/${teamId}`
  );
};

export const selectServicePlan = (payload) => {
  return axios.post(
    `${baseURL}/dev/settings/create/service-plans-work-modes`,
    payload
  );
};

export const updateServicePlan = (payload) => {
  return axios.post(
    `${baseURL}/dev/settings/update/service-plans-work-modes/${payload.id}`,
    payload
  );
};

export const updateActivationLevel = (payload) => {
  return axios.post(`${baseURL}/dev/team/update-activation-level`, payload);
};

export const verifyLinkedinAccessToken = (code) => {
  const redirect_uri = process.env.VUE_APP_BASEURL;
  const client_id = process.env.VUE_APP_LINKEDIN_CLIENT_ID;
  const client_secret = process.env.VUE_APP_LINKEDIN_CLIENT_SECRET;
  let payload = {
    grant_type: "authorization_code",
    redirect_uri,
    client_id,
    client_secret,
    code,
  };
  return axios.post(`https://www.linkedin.com/oauth/v2/accessToken`, payload, {
    headers: {
      "Content-Type": "x-www-form-urlencoded",
    },
  });
};

export const linkedinConfirmToken = (token) => {
  return axios.get(`https://api.linkedin.com/v2/me'`, {
    headers: {
      Authorization: token,
    },
  });
};
export const changeUserType = (payload) => {
  //alert(baseURL);
  return axios.get(`${baseURL}/onboarding/set/usertype/${payload}`);
};

export const saveTeamCompensation = (payload) => {
  return axios.post(`${baseURL}/dev/settings/set/team-compensation`, payload);
};

// api
export const editTeamInfo = (payload) => {
  //console.log(payload);
  return axios.post(`${baseURL}/dev/project/team/edit`, payload);
};

// Swap Team
export const expertSwapTeam = (payload) => {
  return axios.post(
    `${baseURL}/onboarding/dev/swap_invite/${payload.regToken}`,
    payload
  );
};
